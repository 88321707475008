<template>
  <Navbar />
  <router-view />
  <Footer />
</template>

<script lang="ts">
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  components: {
    Navbar,
    Footer,
  },
});
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;700&display=swap");

#app {
  font-family: "Heebo", "Segoe UI", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary-color;
  max-width: 100vw;
}

body {
  padding: 0;
  margin: 0;
  background-color: $primary-color;
  // background-color: magenta;
}

.fa {
  font-family: "Font Awesome 5 Free";
}

// SHARED

.call-to-action {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: $accent-color;
  font-size: 0.9em;
  text-decoration: none;
  font-weight: bold;
  margin: 20px;
  padding: 15px 40px;
  border-style: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  &:active {
    background-color: lighten($accent-color, 10%);
  }

  &:hover {
    background-color: lighten($accent-color, 15%);
  }
}

section {
  font-size: min(10vw, 1.5rem);

  &.content-section {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    padding: 40px 30px 90px 30px;
    background-size: cover;
    background-position: center bottom;

    & > * {
      max-width: min(70vh, 90%);
      margin: 40px;
    }

    & > div {
      flex-grow: 1;
      flex-basis: 500px;
    }

    em {
      &::after {
        content: "\A";
        white-space: pre;
      }

      font-size: 1em;
      font-style: normal;
      font-weight: bold;
      color: $accent-color;
    }

    h1 {
      font-size: 2em;
      text-transform: uppercase;
      color: $accent-color;
      border-bottom: 6px solid $accent-color;
    }

    p,
    h1 {
      margin: 30px;
    }
  }
}

.picture-swipe {
  margin: 0 !important;

  .my-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    & > figure {
      min-width: 300px !important;
      max-width: 40%;

      margin: 10px;
      height: auto;
      // flex-shrink: 1;
      // flex-grow: 1;
      vertical-align: middle;
      object-fit: contain;

      a {
        height: 100%;
        width: 100%;
      }

      img {
        height: 100%;
        width: 100%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
  }
}
</style>
