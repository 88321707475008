<template>
  <nav id="hamnav">
    <label for="hamburger">&#9776;</label>
    <input type="checkbox" id="hamburger" />

    <router-link to="/" class="logo">
      <img
        alt="Game Logotype"
        src="../tinified/logo-wide.png"
        width="388"
        height="67"
      />
    </router-link>

    <div class="grow"></div>

    <div id="hamitems">
      <router-link to="/">Home</router-link>
      <router-link to="/#about">The Game</router-link>
      <router-link to="/funding">Be a Supporter</router-link>
    </div>
    <div class="mask"></div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Navbar",
  props: {
    msg: String,
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#hamnav {
  z-index: 10;

  .logo {
    max-width: 400px;
    flex-basis: 100px;
    flex-grow: 10;
    flex-shrink: 1;
    margin: 0px 20px;
    display: block;
    & > img {
      vertical-align: middle;
      object-fit: contain;
      width: 100%;
      max-height: 60px;
      padding: 5px 0px;
    }
  }

  .grow {
    flex-grow: 1;
    flex-shrink: 0;
  }

  // COMMON
  top: 0;
  min-height: 80px;
  position: sticky;
  padding: 5px 5% 0px 10%;
  background-color: $primary-color;
  overflow-y: visible;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #fff;
  flex-wrap: wrap;

  & label,
  #hamburger {
    display: none;
  }

  #hamitems {
    display: flex;
    justify-content: right;
    align-items: center;
    margin: 10px;

    & a {
      padding: 10px 22px;
      font-size: 1.35em;
      color: white;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        background-color: darken($primary-color, 5%);
      }
    }
  }

  @media screen and (max-width: 1000px) {
    & label {
      display: inline-block;
      color: white;
      background: $primary-color;
      font-style: normal;
      font-size: 2em;
      padding: 10px;
    }

    #hamitems {
      display: none;
      flex-basis: 100%;

      & a {
        box-sizing: border-box;
        display: block;
        width: 100%;
        border-top: 1px solid $accent-color;
      }
    }

    & input:checked ~ #hamitems {
      display: block;
    }
  }
}

.mask {
  pointer-events: none;
  position: absolute;
  background-color: $primary-color;
  height: 50px;
  transform: translateY(calc(100% - 2px));
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  mask-image: url("./mask-navbar.svg");
  mask-position: top center;
  // mask-repeat: no-repeat;
  mask-repeat: repeat-x;
  // mask-size: cover;
  // mask-size: contain;
  mask-size: auto;
}
</style>
