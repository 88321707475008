<template>
  <footer class="footer">
    <div class="mask"></div>
    <div class="social">
      <a href="https://www.facebook.com/FurryWorldGame" class="fab">
        <div class="facebook"></div>
      </a>
      <a href="https://twitter.com/FurryWorldGame" class="fab">
        <div class="twitter"></div>
      </a>
      <a href="https://www.instagram.com/furryworldgame" class="fab">
        <div class="instagram"></div>
      </a>
      <a
        href="https://www.youtube.com/channel/UCT9QH5sB2xd-xdfodXMWNyg"
        class="fab"
      >
        <div class="youtube"></div>
      </a>
      <a href="https://discord.gg/Bkus5gNsez" class="fab">
        <div class="discord"></div>
      </a>
    </div>

    <router-link to="/" class="logo"
      ><img
        alt="Game Logotype"
        src="../tinified/logo-wide.png"
        width="388"
        height="67"
      />
    </router-link>

    <!-- <h3><a>Privacy Policy</a></h3> -->
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  padding: 0px 30px 30px 30px;
  // height: 415px;
  background-color: $primary-color;

  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin: 2 0px;
    font-size: 0.8em;
    font-weight: lighter;
    color: #9a9b9c;
    text-transform: uppercase;
  }

  .social {
    margin: 25px 0;
    padding: 0 20px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .fab {
      width: 30px;
      height: 30px;

      margin: 10px;
      padding: 10px;
      border-radius: 50%;
      background-color: $accent-color;

      &:active {
        background-color: lighten($accent-color, 10%);
      }

      &:hover {
        background-color: lighten($accent-color, 15%);
      }

      & > * {
        &.facebook {
          mask-image: url("./social/facebook.svg");
        }
        &.twitter {
          mask-image: url("./social/twitter.svg");
        }
        &.instagram {
          mask-image: url("./social/instagram.svg");
        }
        &.youtube {
          mask-image: url("./social/youtube.svg");
        }
        &.discord {
          mask-image: url("./social/discord.svg");
        }

        content: "";
        height: 100%;
        width: 100%;
        background-color: $primary-color;
        mask-position: center center;
        mask-repeat: no-repeat;
        mask-size: contain;
      }
    }
  }

  .logo {
    margin: 0px 25px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.mask {
  $height: 100vw;

  pointer-events: none;
  position: absolute;
  background-color: $primary-color;
  height: $height;
  transform: translateY(calc(-#{$height} + 2px));
  width: 100%;
  mask-image: url("./mask-footer.svg");
  mask-position: bottom center;
  mask-repeat: no-repeat;
  mask-size: contain;
}

// * {
//   margin-block: 0;
// }
</style>
