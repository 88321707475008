import * as Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

export const app = Vue.createApp(App)
  .use(store)
  .use(router)
  .mount("#app");

const DEFAULT_TITLE = window.document.title;

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  app.$nextTick(() => {
    document.title = to.name
      ? `${DEFAULT_TITLE} - ${to.name.toString()}`
      : DEFAULT_TITLE;
  });
});
